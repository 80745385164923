@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.filter-component {
  .font-weight-bold {
    color: #000000;
  }
}

.btn-success .p-button-label {
  margin-bottom: 0 !important;
}

.cus-calendar {
  .p-inputtext {
    outline: unset !important;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    border-right: unset !important;
    border-radius: 10px;
    border: unset;
  }
}

.btn-group-cust-content {
  .p-inputtext,
  .p-togglebutton,
  .p-selectbutton,
  .p-inputgroup {
    box-shadow: unset;
  }

  .p-inputtext {
    padding: unset;
  }

  .p-dropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .btn-conditional {
    border: 1px solid #c4c4c4;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;

    i {
      font-size: 12px;
    }
  }

  .area-conditional {
    width: 100%;
    border: 1px solid #e8e8e8;
    padding: 10px 20px;
    position: relative;
    border-radius: 10px;

    .p-dropdown {
      border-radius: 10px;
    }

    .btn-close {
      padding: 0px 2.5px;
      border: unset;
      background-color: #c8c8c8;
      border-radius: 100%;
      display: -moz-box;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: center;
      justify-content: center;
      width: 15px;
      height: 15px;
    }

    .company-role {
      border-radius: unset;
    }

    .p-dropdown-label {
      font-weight: normal !important;
      font-size: 14px;
    }

    .p-message {
      width: 100%;

      .p-message-wrapper {
        padding: 5px;

        .p-message-icon {
          font-size: 1rem;
          margin-right: 0.5rem;
        }

        .p-message-summary {
          font-size: 14px;
        }

        .p-message-detail {
          font-size: 14px;
        }
      }
    }
  }
}

.page-hv1 {
  .p-dropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .p-dropdown-label .p-inputtext {
    border-bottom: unset !important;
  }
}

.p-message .p-message-wrapper {
  padding: 5px !important;
  font-size: 14px;
}

.section-approve {
  // .p-accordion .p-accordion-header .p-accordion-header-link {
  //   padding: 0.25rem 0.75rem;
  // }

  .radio-check {
    .p-highlight {
      border-color: #28a745 !important;
      background: #28a745 !important;
    }
  }

  .p-accordion-header-text {
    font-size: 14px;
  }

  .p-accordion
    .p-accordion-header
    .p-accordion-header-link
    .p-accordion-toggle-icon {
    font-size: 14px;
  }

  .p-accordion-header-link {
    border: unset !important;
    background-color: #fff !important;
  }

  .p-accordion-header-link:hover {
    text-decoration: none;
  }

  .p-accordion-header {
    .pi {
      font-size: 14px;
      display: none;
    }
  }

  .link {
    text-decoration: underline;
    font-weight: normal;
    font-size: 13px;
  }

  .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: unset !important;
  }

  // .p-accordion-toggle-icon{
  //   display: none;
  // }

  .p-accordion-content {
    font-size: 14px !important;
    padding-top: unset !important;
    border: unset !important;
  }
}

.bell {
  color: #000;
  -webkit-animation: ring 3s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 3s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 3s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.company-role {
  .p-chip {
    margin: 2.5px;
    background: #ebf8ff;
    color: #2d9cdb;
    font-size: 14px;
  }

  .p-chip-remove-icon{
    font-size: 12px;
  }
}

.category{
  textarea.form-control{
    font-size: 14px;
    height: auto;
  }
}


.tbl-detail-survey{
  margin-bottom: -1px!important;
}





.input-file-container {
  position: relative;
  width: 300px;
  padding-top: 20px;
} 
.input-file-container .input-file-trigger {
  display: block;
  padding: 14px 45px;
  background: #fcb64b;
  color: #fff;
  font-size: 1em;
  transition: all .4s;
  cursor: pointer;
}
.input-file-container .input-file {
  position: absolute;
  top: 0; left: 0;
  width: 225px;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}
.input-file-container .input-file:hover + .input-file-trigger,
.input-file-container .input-file:focus + .input-file-trigger,
.input-file-container .input-file-trigger:hover,
.input-file-container .input-file-trigger:focus {
  background: #34495E;
  color: #fcb64b;
}

.input-file-container .file-return {
  margin: 0;
}
.input-file-container .file-return:not(:empty) {
  margin: 1em 0;
}
.input-file-container .file-return {
  font-style: italic;
  font-size: .9em;
  font-weight: bold;
}
.input-file-container .file-return:not(:empty):before {
  content: "Selected file: ";
  font-style: normal;
  font-weight: normal;
}

// .detail-survey-data{
//   .btn-edit{
//     visibility: hidden;
//   }
// }

// .detail-survey-data:hover{
//   .btn-edit{
//     visibility: visible;
//   }
// }

.btn-approve-change {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f9fe!important;
  border: unset;
  color: #1a74bc!important;
  padding: unset;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
}

.content-image{
  width: calc(100% - 25px);
  display: flex;
}

.btn-close{
  border: unset;
  border-radius: 50%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 10px;
  // height: 10px;
  .pi{
    font-size: 14px;
  }
}

.btn-add-images{
  width: 44px;
  height: 30px;
  border: 1px dashed #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: 5px;
}

.btn-inputtype-group{
  .pi{
    font-size: 12px;
  }
}
.company-role{
  .p-dropdown-label{
    padding: unset;
    border: unset;
    box-shadow: unset;
  }

  .p-dropdown-trigger{
    width: 2rem!important;
  }
  
  
}

.btn-group-cust-content{
  // .form-control{
  //   font-size: 14px;
  //   height: auto;
  //   border: 1px solid #ededed;
  // }

  // .p-inputtext{
  //   border: 1px solid #ededed;
  // }
}

.p-accordion .p-accordion-content{
  border-top: unset!important;
}

// .detail-survey{
//   .p-inputtext{
//     padding: 5px 10px!important;
//     font-size: 14px;
//   }

//   .p-inputnumber-input{
//     text-align: right;
//     font-size: 14px;
//     // color: red;
//   }

//   // .p-dropdown-trigger{
//   //   width: 1.75rem!important;
//   // }
// }

.table>:not(:first-child){
    border-top: unset!important;
}

.card-report{
  font-family: 'Roboto';
}

.p-dropdown-empty-message{
  font-size: 13px!important;
  font-family: 'Roboto', sans-serif !important;
}


body .p-dropdown-panel{
  z-index: 9999!important;
}

input[type="image"]:focus {
  outline: none;
  box-shadow: none;
}

.p-dialog-header-close{
  outline: none;
  box-shadow: none;
}

.p-multiselect-panel .p-multiselect-header{
  padding: 5px 10px!important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item{
  padding: 5px 10px!important;
  font-size: 14px;
  height: 40px;
}


.block-ui {
  position: relative;
  min-height: 3em;
}

.block-ui-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 2em;
  cursor: wait;
  overflow: hidden;
}

.block-ui-container:focus {
  outline: none;
}

.block-ui-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.75;
  filter: alpha(opacity=50);
  background-color: rgb(184, 184, 184);
  z-index: 999999;
}

.block-ui-message-container {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
  z-index: 10001;
}

.block-ui-message {
  color: #333;
  background: none;
  z-index: 1011;
}

#indicator {
  width: 100px;
  height: 100px;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  animation: spin 1s linear infinite;
}

#circle {
  fill: none;
  stroke: rgba(255, 255, 255, 0.5);
  stroke-linecap: round;
  stroke-width: 4;
  animation: draw 3s ease-in-out infinite;
}

@keyframes draw {
  0% {
    stroke-dasharray: 20, 282.6;
  }
  50% {
    stroke-dasharray: 200, 282.6;
  }
  100% {
    stroke-dasharray: 20, 282.6;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
