@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


// @font-face {
//   font-family: Helvetica;
//   src: url(https://file.tengroup.com.vn/wwwroot/02092021/0AW5E4o5DaFkcEue57YOiKhxC28WfISOUuxAC1HIoAYRWClFrYCakBfMms6zMpmNffeECjVktAiMs4A0.otf);
// }

// @font-face {
//   font-family: Montserrat;
//   src: url(https://file.tengroup.com.vn/wwwroot/03092021/szDAeNle2tZecI1jFCuOEqY7RaoKy0LHFDRyZtFmHl7S1y0PJkoGENqx8o1vnzMBF0DxauliJNr9Ghio.otf);
// }

html,
body,
#__next {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  font-family: 'Roboto', sans-serif!important;
}

//  /*
//  *  STYLE 1
//  */

//  #__next::-webkit-scrollbar-track
// {
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// 	border-radius: 10px;
// 	background-color: #F5F5F5;
// }

// #__next::-webkit-scrollbar
// {
// 	width: 8px;
// 	background-color: #F5F5F5;
// }

// #__next::-webkit-scrollbar-thumb
// {
// 	border-radius: 10px;
// 	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
// 	background-color: #555;
// }

#__next {
  // background-color: #FFDDAB!important;
  background-color: #fff !important;
  font-family: Montserrat;
  width: 100%;

  .p-button-info-customize {
    color: #1a74bc !important;
    background-color: #fff !important;
  }

  .p-button-danger-customize {
    color: #ff593e !important;
    background-color: #fff !important;
  }

  /*new landing-page*/
  .new_landing-page {
    width: 100vw;
    height: 100vh;
    position: relative;
    // margin-top: -100px;
    font-family: 'Roboto', sans-serif;

    .container {
      // padding-top: 20vh;
      margin: 0px;
      padding-right: 60px !important;
      padding-left: 78px !important;
    }

    .box1 {
      width: 10px;
      height: 95px;
      position: absolute;
      background-color: #d48104;
      top: 20vh;
      left: 0;
    }

    .line1 {
      width: 2px;
      background-color: #c8c8c8;
      position: absolute;
      left: calc(50% - 1px);
      height: 100%;
      top: 0;
    }

    .box2 {
      width: 10px;
      height: 95px;
      position: absolute;
      background-color: #b1cbc0;
      top: 50%;
      right: calc(50% + 1px);
    }

    .line2 {
      width: 2px;
      background-color: #fdb64c;
      position: absolute;
      right: 25%;
      height: 100%;
      top: 0;
      z-index: 1;
    }

    .box3 {
      width: 10px;
      height: 95px;
      position: absolute;
      background-color: #fdb64c;
      top: 20%;
      left: calc(75% - 1px);
    }

    .box4 {
      width: 10px;
      height: 95px;
      position: absolute;
      background-color: #260807;
      bottom: 0;
      right: 9px;
    }

    .button-image-lp {
      // border-radius: 75px;
      height: 59px;
      // height: 208px;
    }

    .text-bold-mg20 {
      font-weight: bold !important;
      margin-bottom: 20px;
    }

    .person-male {
      position: absolute;
      height: calc(75% - 95px);
      width: 20%;
      bottom: 0;
      right: calc(25% + 1px);
      background-color: #fef1d0;
    }

    .person-male-image {
      position: absolute;
      height: calc(75% - 95px);
      width: 20%;
      right: calc(25% + 1px);
      bottom: 0;
    }

    .person-male-content {
      height: 100%;
      width: 100%;
    }

    .person-female {
      position: absolute;
      height: 70%;
      width: 20%;
      top: 15%;
      right: 0;
      background-color: #f3f8f9;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .person-female-image {
      position: absolute;
      height: 70%;
      width: 20%;
      top: 15%;
      right: 0;
    }

    .person-female-content {
      height: 100%;
      width: 100%;
      // object-fit: fill;
    }

    .banner-update {
      top: 40px;
      height: calc(100% - 133px);
      right: 0;
      max-width: 67.5% !important;
      position: absolute;
    }

    .banner-update > img {
      height: 100%;
      object-fit: contain;
    }
  }

  .new_landing-product {
    width: 100vw;
    height: 100vh;
    // background-color: #ffefce !important;
    background-color: #fff !important;
    z-index: 10;
    display: flex;
    position: relative;

    .div-product {
      width: 50%;
      height: 100%;
      position: relative;

      .div-box1 {
        height: 30%;
        width: 100%;
        position: absolute;
        bottom: -3px;
      }

      .div-box1 > img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        margin: auto;
      }

      .div-box2 {
        height: 32%;
        width: auto;
        position: absolute;
        top: 30%;
        opacity: 0.7;
      }

      .div-box2 > img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        margin: auto;
      }

      .div-box3 {
        height: 40%;
        width: auto;
        position: absolute;
        bottom: 15%;
        right: -12%;
        opacity: 0.8;
      }

      .div-box3 > img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        margin: auto;
      }

      .div-box4 {
        height: 19%;
        width: auto;
        position: absolute;
        bottom: 0%;
        right: -3%;
        opacity: 0.4;
      }

      .div-box4 > img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        margin: auto;
      }

      .div-phone {
        padding-top: 50px;
        height: 74%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 10;
      }

      .div-phone-container {
        height: 100%;
        display: flex;
        justify-content: center;
      }

      .div-phone-container > img {
        height: 100%;
        // width: 100%;
        object-fit: contain;
        margin: auto;
        z-index: -1;
        padding: 6px;
        background-color: #fff;
        border-radius: 45px;
        // border-radius: 50px;
      }

      .dot-line-vertical {
        // width: 1px;
        height: 250px;
        left: 38.25px;
        top: 24.5px;
        position: absolute;
        border-left: 1px dashed #000;
      }

      .text-desciption li {
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        list-style: none;
        // display: flex;
        // align-items: center;
      }

      // .text-desciption li:nth-child(odd) {
      //     list-style: disc;
      // }

      // .text-desciption li:nth-child(even) {
      //     list-style: none;
      // }
    }
  }

  .new_landing-text {
    width: 100vw;
    min-height: 30px;
    background-color: #ffffff !important;
    display: flex;

    .p-carousel .p-carousel-indicators {
      visibility: hidden;
    }

    .p-carousel .pi-chevron-left::before {
      content: url("/img/icons/circle-black-left.svg");
      color: #fff;
      // background-color: #000;
      border-radius: 100%;
      // font-size: 32px;
    }

    .p-carousel .pi-chevron-right::before {
      content: url("/img/icons/circle-black-right.svg");
      color: #fff;
      // background-color: #000;
      border-radius: 100%;
      // font-size: 32px;
    }

    .div-product {
      width: 50%;
      height: 100%;
      position: relative;
      padding: 75px 30px;

      .carousel-item > img {
        border-radius: 30px !important;
      }

      .dot-line-vertical {
        // width: 1px;
        height: 300px;
        left: 38.25px;
        top: 24.5px;
        position: absolute;
        border-left: 1px dashed #000;
      }

      .text-desciption li {
        min-height: 50px;
        line-height: 50px;
        font-size: 20px;
        list-style: none;
      }
    }

    .p-carousel-item {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #c4c4c4;
    }

    .p-carousel-item:nth-child(5n) {
      border-right: unset;
    }

    .p-carousel-item img {
      max-width: 70%;
      height: 100px;
      object-fit: contain;
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      padding: 10px;
    }
  }

  .new_landing-text-2 {
    width: 100vw;
    min-height: 637px;
    background-color: #fdb64c !important;
    display: flex;
    flex-direction: row;
    padding: 186px 0;
    position: relative;

    .text-contact {
      min-height: 25px;
      width: 100%;
      background-color: #faf7f5;
      padding: 40px 20px;

      .text-contact-content {
        width: 50%;
        padding: 40 10px;

        span {
          font-size: 20px;
        }
      }
    }

    .form-contact {
      position: absolute;
      width: 794px;
      height: 537px;
      right: 10%;
      top: 10%;
      background-color: #fff;
      border-radius: 30px;

      .p-card {
        height: 100%;
        background-color: #fff;
        border-radius: 30px;
        border: unset;
      }

      .p-input-icon-left {
        width: 100%;
      }
    }
  }

  .h4 {
    margin-bottom: unset !important;
  }

  /*main-page*/
  .layout-page {
    width: 100%;
    min-height: 100%;
    position: relative;
    background-color: #faf7f5;
  }

  .btn-account {
    border: unset;
    outline: 0;
    background-color: transparent;
    font-family: Montserrat;
  }

  .octopus-content {
    width: 100%;
    min-height: 100%;
    position: relative;
    // padding-top: 10px;
    // .menu-octopus{
    //     padding-top: 80px;
    //     border-top-right-radius: 90px;
    // }

    .list-group-item {
      border: unset !important;
      text-align: center;
    }

    .list-group-item.active {
      background-color: #fafafb !important;
      color: #fdb64c !important;
      font-weight: bold !important;
      border-right: 4px solid #fdb64c !important;
    }

    .separator {
      width: 35px;
      margin-top: unset;
      margin-bottom: unset;
      border: 0;
      border-top: 1.5px solid #fdb64c;
    }

    .nav-item-main {
      font-weight: bold;
    }

    .main-octopus-content {
      width: calc(100% - 206px);
      min-height: 100%;
      position: relative;
    }

    .page-footer {
      width: 100%;
      padding: 10px;
      font-size: 12px;
      height: 45px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin-top: 20px;

      .logo-software {
        height: 26px;
        margin-right: 10px;
        object-fit: contain;
      }
    }
  }

  .octopus-dataview {
    .p-dataview .p-dataview-header {
      padding: unset;
      background-color: transparent;
      border-color: transparent;
      color: #000;
    }

    .data-view-toolbar {
      padding: 0 15px;
      margin: unset;
      font-family: 'Roboto', sans-serif;

      .p-selectbutton {
        background-color: #e0e0e0;
        border-color: #e0e0e0;
        border-radius: 10px;
      }

      .p-selectbutton .p-button {
        background-color: #e0e0e0;
        border-color: #e0e0e0;
        color: #828282;
      }

      .p-selectbutton .p-button.p-highlight {
        background-color: #4f4f4f;
        border-color: #4f4f4f;
        color: #fff;
      }

      .h5 {
        margin-bottom: unset;
      }
    }
  }

  .octopus-dataview > .p-dataview-content {
    padding: 0px 18px !important;
  }

  .filter-component {
    background-color: #f8f8f8 !important;
    margin-top: 10px;
    border: unset !important;
    position: relative;
    padding: 10px 20px 30px 20px !important;
    margin-bottom: unset !important;
    border-radius: 10px !important;

    .form-control {
      border-radius: 10px;
      box-shadow: unset;
    }

    button.close {
      border-radius: 100% !important;
      border: 2px solid #000000 !important;
      color: #000000 !important;
      top: 15px !important;
      right: 15px !important;
      font-size: 18px !important;
      padding: unset;
      width: 24px !important;
      height: 24px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .p-dataview-emptymessage {
    text-align: center;
  }

  .p-paginator {
    border: unset;
  }

  .p-card .p-card-content {
    padding: 10px !important;
    min-height: 135px;
  }
}

.user-content {
  width: calc(100% - 175px);
  // margin-left: 30px;

  .button-upload {
    border-radius: 10px !important;
    outline: unset;
    box-shadow: unset;
    background-color: #e9e9e9;
    border: unset;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.p-datatable-emptymessage > td {
  justify-content: center !important;
}

/*global*/

.dialog-open {
  filter: blur(15px);
}

.p-dialog .p-dialog-header {
  padding: unset !important;
  background: #fff !important;
  border-bottom: unset !important;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
  color: #000 !important;
  padding-left: 1rem !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2.5rem !important;
  height: 2.5rem !important;
  border-radius: unset !important;
}

.p-dialog .p-dialog-header .p-dialog-header-close-icon {
  color: #000 !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #000 !important;
}

.p-dialog-resizable .p-dialog-header {
  cursor: unset !important;
}

.p-dialog .p-dialog-content {
  color: #000000 !important;
}

.dialog-login {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;

  .p-float-label label {
    left: 7.5px !important;
  }

  .p-inputgroup .p-float-label:first-child input {
    border: 1px solid #ebebeb !important;
    border-right: unset !important;
    outline: unset !important;
    box-shadow: unset !important;
  }

  .p-inputgroup-addon:last-child,
  .p-inputgroup button:last-child,
  .p-inputgroup input:last-child,
  .p-inputgroup > .p-inputwrapper:last-child,
  .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
    border: 1px solid #ebebeb !important;
    background-color: transparent !important;
    border-left: unset !important;
  }

  .p-float-label input:focus ~ label,
  .p-float-label input.p-filled ~ label,
  .p-float-label textarea:focus ~ label,
  .p-float-label textarea.p-filled ~ label,
  .p-float-label .p-inputwrapper-focus ~ label,
  .p-float-label .p-inputwrapper-filled ~ label {
    top: -0.85rem !important;
    font-size: 14px !important;
  }

  .form-control {
    height: 45px;
    padding: 14px 25px;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    border-radius: 10px;
  }

  .btn-facebook {
    outline: unset !important;
    box-shadow: unset !important;
    border: 1px solid #ebebeb !important;
    border-right-color: #56ccf2 !important;
    border-right-width: 14px !important;
    border-radius: 10px;
    height: 45px;
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
    width: 100%;
    background: transparent;
  }

  .btn-gmail {
    outline: unset !important;
    box-shadow: unset !important;
    border: 1px solid #ebebeb !important;
    border-right-color: #f2994a !important;
    border-right-width: 14px !important;
    border-radius: 10px;
    height: 45px;
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
    width: 100%;
    background: transparent;
  }

  .register-phone > .form-control {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    padding: 0.5rem 0.9rem;
    height: auto;
    border: 1px solid #ebebeb;
    color: #828282;
  }

  .register-phone > .form-control:focus,
  .register-phone > .form-control:active {
    outline: 0;
    box-shadow: unset;
  }

  .register-phone > .input-group-append {
    display: flex;
    justify-content: center;
    align-items: center;

    .input-group-text {
      background-color: unset !important;
      border: unset;
    }
  }

  .p-inputtext {
    border-radius: 10px !important;
    // color: #EBEBEB!important;
    border-color: #ebebeb !important;
    box-shadow: unset !important;
    outline: unset !important;
  }
}

.btn-link {
  border: unset !important;
  background-color: unset !important;

  .lnk-register {
    color: #2d9cdb;
    font-size: 12px;
    font-style: italic;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }
}

.btn-link:hover {
  text-decoration: underline !important;
  text-decoration-color: #2d9cdb !important;
}

.btn-warning {
  background-color: #fcb64b !important;
  border: 1px solid #fcb64b !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.btn-success {
  background-color: #22C55E !important;
  border-color: #22C55E !important;
  font-weight: 500 !important;
  color: #fff !important;

  .p-button-label {
    font-size: 12px !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
  }
}

.btn-secondary {
  background-color: #260807 !important;
  border-color: #260807 !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.p-message-summary {
  font-weight: 500 !important;
  margin-right: 10px !important;
}

.p-toast .p-toast-message .p-toast-message-content {
  text-align: left !important;
}

.btn-prev {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0px 10px !important;
  outline: unset !important;
  box-shadow: unset !important;
}

.btn-prev > i {
  color: #a6a6a6;
}

.btn-next {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0px 10px !important;
  outline: unset !important;
  box-shadow: unset !important;
}

.btn-next > i {
  color: #a6a6a6;
}

.card-company {
  box-shadow: 3px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: unset;
  color: #000;
  font-size: 12px !important;
  min-height: 170px !important;
  position: relative;
  // pointer-events: none;

  .text-desc {
    color: #929090;
    pointer-events: none;
  }

  .h6 {
    font-size: 14px;
    pointer-events: none;
  }

  .logo-company {
    width: 55px;
    height: 55px;
    pointer-events: none;
  }

  .content-company {
    width: calc(100% - 55px);
    height: 100%;
    pointer-events: none;
  }
}

.div-card {
  position: relative;
  cursor: pointer;

  .div-action {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 99;
  }
}

.image-upload {
  position: relative;
  width: 100%;
  height: 100%;

  .btn-browsers {
    position: absolute;
    bottom: 5px;
    right: 5px;

    .p-button.p-button-icon-only {
      width: auto;
    }

    .btn-choose-file {
      background-color: #fff;
      opacity: 0.5;
      border-color: transparent;
      color: #000;
      padding: 0px 2.5px;
    }
  }
}

.image-upload > img {
  border-radius: 10px;
  object-fit: cover;
}

.company-avatar {
  margin-top: -40px;
  padding: 10px 50px;
  width: 50%;

  .company-logo {
    border-radius: 10px;
    z-index: 10;
  }

  .p-dialog .p-dialog-header .p-dialog-title {
    color: #000000 !important;
  }
}

.p-dialog .p-dialog-footer button:last-child {
  margin: unset !important;
}

.p-checkbox-label {
  margin-left: 10px;
  margin-bottom: unset !important;
}

label,
span {
  margin-bottom: unset !important;
}

.p-calendar-w-btn .p-inputtext {
  outline: unset !important;
  box-shadow: unset !important;
  border-right: unset !important;
}

.p-calendar-w-btn .p-button {
  background: #fff !important;
  color: #000 !important;
  outline: unset !important;
  box-shadow: unset !important;
  border: 1px solid #dadada !important;
}

.p-datepicker table td > span {
  width: unset !important;
  height: unset !important;
  padding: 5px !important;
  font-size: 14px;
}

.p-datepicker table th > span {
  width: unset !important;
  height: unset !important;
  padding: 5px !important;
  font-size: 14px;
}

.p-datepicker .p-datepicker-header {
  padding: unset !important;
  font-size: 14px;
}

.separator-company {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1.5px dashed #fdb64c;
}

.p-tag + .p-tag {
  margin-right: 10px;
}

.create-header-input {
  .p-dropdown,
  .form-control {
    border-radius: 10px;
    border-color: transparent;
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif;
  }
}

.setting-input {
  padding: 15px 30px;
  border: 1px solid #ededed;
  border-radius: 10px;
  font-size: 14px;

  .p-dropdown-label,
  .p-dropdown-item,
  .p-dropdown {
    font-size: 14px !important;
    border-color: transparent;
    // background-color: ;
  }

  .p-dropdown,
  .p-inputtext {
    border-radius: 10px;
  }
}

.p-button {
  border-radius: 10px !important;
}

.item-input-group {
  padding: 10px 5px 10px 10px;
  border: 1px solid #ededed;
  border-radius: 10px;
  //   .p-card-content{
  //     padding: 0!important;
  //   }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-radius: 10px !important;
  }

  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-radius: 10px !important;
  }

  .btn-inputtype-group:not(:disabled):not(.disabled):active,
  .btn-inputtype-group:not(:disabled):not(.disabled).active,
  .show > .btn-inputtype-group.dropdown-toggle {
    background-color: rgba($color: #22C55E, $alpha: 0.26);
    color: #128200;
    border-radius: 10px !important;
    border: unset;
    height: 45px;

    .icon {
      display: block;
    }
  }

  .btn-inputtype-group {
    background-color: #ededed;
    border-radius: 10px !important;
    color: #414042;
    border: unset;
    outline: unset !important;
    box-shadow: unset !important;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    font-size: 14px !important;
    height: 45px;
    font-family: 'Roboto', sans-serif;

    .icon {
      display: none;
    }
  }

  .btn-inputtype-group:hover {
    background-color: rgba($color: #22C55E, $alpha: 0.26);
    color: #128200;
    border-radius: 10px !important;
    border: unset;
    outline: unset !important;
    box-shadow: unset !important;
    height: 45px;
  }

  .btn-inputtype:not(:disabled):not(.disabled):active,
  .btn-inputtype:not(:disabled):not(.disabled).active,
  .show > .btn-inputtype.dropdown-toggle {
    background-color: rgba($color: #22C55E, $alpha: 0.26);
    color: #128200;
    border-radius: 10px !important;
    border: unset;
    height: 45px;

    .icon {
      display: block;
    }
  }

  .btn-inputtype {
    background-color: #ededed;
    border-radius: 10px !important;
    color: #414042;
    border: unset;
    outline: unset !important;
    box-shadow: unset !important;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    height: 45px;

    .icon {
      display: none;
    }
  }

  .btn-inputtype:hover {
    background-color: rgba($color: #22C55E, $alpha: 0.26);
    color: #128200;
    border-radius: 10px !important;
    border: unset;
    outline: unset !important;
    box-shadow: unset !important;
  }

  .btn-inputtype-status:not(:disabled):not(.disabled):active,
  .btn-inputtype-status:not(:disabled):not(.disabled).active,
  .show > .btn-inputtype-status.dropdown-toggle {
    background-color: rgba($color: #22C55E, $alpha: 0.26);
    color: #128200;
    border-radius: 10px !important;
    border: unset;
    .icon {
      display: block;
    }
  }

  .btn-inputtype-status {
    background-color: #ededed;
    border-radius: 10px !important;
    color: #414042;
    border: unset;
    outline: unset !important;
    box-shadow: unset !important;
    display: flex;
    align-items: center;
    justify-content: space-between !important;

    .icon {
      display: none;
    }
  }

  .btn-inputtype-status:hover {
    background-color: rgba($color: #22C55E, $alpha: 0.26);
    color: #128200;
    border-radius: 10px !important;
    border: unset;
    outline: unset !important;
    box-shadow: unset !important;
  }
}

.p-breadcrumb {
  border: unset !important;
  background-color: transparent !important;
  font-size: 12px !important;
  padding: 10px !important;
}

.p-menuitem-icon {
  font-size: 12px !important;
}

.p-breadcrumb-chevron {
  font-size: 8px !important;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  font-size: 14px !important;
  padding: 5px !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  font-size: 14px !important;
  padding: 5px !important;
}

#__next .p-datatable .p-datatable-thead > tr > th {
  border: unset;
  border-bottom: 1px dashed #fdb64c;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #000 !important;
}

#__next .p-datatable .p-datatable-tbody > tr > td {
  border: unset;
  border-bottom: 1px dashed #c4c4c4;
  font-size: 14px;
  // font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #000 !important;
  width: 100%;
  // justify-content: center;
  // display: flex;
}

#__next .p-datatable-emptymessage > td {
  text-align: center !important;
}

#__next .p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: unset;
}

.create-header-input .p-card-content {
  padding: unset !important;
}

.create-header-input {
  background: #e3eff9 !important;
  border: unset !important;
  box-shadow: unset !important;
  border-radius: 10px !important;
}

// .setting-input .p-card-content{
//     padding: unset!important;
// }

// .setting-input > .p-inputtext:enabled:focus{
//     border-color: #c4c4c4!important;
//     color: #000;
// }

.btn-input-screen-del {
  width: unset !important;
  padding: unset !important;
  color: #ff593e !important;
  box-shadow: unset !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-input-screen {
  width: unset !important;
  padding: unset !important;
  color: #2d9cdb !important;
  box-shadow: unset !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

#__next .p-fieldset .p-fieldset-legend {
  width: auto !important;
  margin-left: 10px !important;
  margin-bottom: unset;
  background: unset !important;
  color: #000;
  border: unset;
  font-size: 20px;
  font-weight: 500;

  .p-fieldset-legend-text {
    color: #000;
  }

  .user-content {
    margin-left: 40px;
  }
}

.btn-set-role {
  background-color: #ededed !important;
  color: #000 !important;
  border: unset;
  border-radius: 10px !important;
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif;
  padding: 5px 16px;
  width: 100%;
}

.btn-set-role-check {
  background-color: #f1f9fe !important;
  color: #1a74bc !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif;
  border: unset;
  border-radius: 10px;
  width: 100%;
}

.p-password,
.p-password-input {
  width: 100% !important;
}

.p-colorpicker-preview {
  height: 38px !important;
}

.btn-upload {
  width: 100%;
  height: 100px;
  border: 2px dashed #ebebeb;
  background-color: transparent;
  // color: #1A74BC !important;
}

.p-scrollpanel .p-scrollpanel-bar {
  background-color: #fdb64c !important;
}

.date-range-button {
  border-radius: 15px;
  background-color: #fff;
  display: flex;
  // justify-content: center;
  align-items: center;

  .tab-list-days {
    display: flex;
    overflow-x: hidden;
    white-space: nowrap;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    position: relative;

    .btn-days {
      border: 1px solid #fff;
      padding: 28px 15px;
      background-color: #fff;
      position: relative;
      display: flex;
      flex-direction: row;
      font-weight: bold;
    }

    .dots-days {
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 100%;
      background-color: #f3941f;
      position: absolute;
      bottom: 6px;
      left: calc(50% - 3.5px);
    }

    .btn-days.active {
      background: linear-gradient(
        0.25turn,
        transparent,
        rgba(149, 195, 232, 0.2),
        transparent
      );
      color: #000 !important;
      // opacity: 0.5;
      // border: 1px solid #2D9CDB;
    }

    .btn-days:first-child {
      margin-left: 15px;
    }

    .btn-days:last-child {
      margin-right: 20px;
    }
  }

  .tab-control {
    background-color: transparent;
    // display: inline-block;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 28px 7.5px;
    opacity: 0;
    font-size: 17px;
  }

  .tab-control:hover {
    opacity: 1;

    .arrow-left {
      opacity: 1;
    }

    .arrow-right {
      opacity: 1;
    }
  }

  .arrow-left {
    position: absolute;
    left: 0;
    margin-left: 185px;
    z-index: 99;
  }

  .arrow-right {
    position: absolute;
    right: 0;
    margin-right: 45px !important;
    z-index: 99;
  }
}

.btn-current-month {
  background-color: #2d9cdb !important;
  color: #fff !important;
  border: 1px solid #2d9cdb;
  padding: 28px 15px !important;
  width: 140px;
  border-radius: 15px;
  position: relative;
  font-weight: bold;

  .div-carret {
    position: absolute;
    left: calc(50% - 5px);
    bottom: 5px;
  }
}

#txtChooseMonth {
  .p-highlight {
    background-color: #2d9cdb !important;
    color: #fff !important;
    font-weight: bold;
  }
}

.dots-days.active {
  background-color: #f3941f !important;
}

.info-store {
  .p-card-content {
    min-height: 90px !important;
  }
}

// .detail-maps {
//   background-color: #fff;
//   padding: 30px;
//   font-family: 'Roboto', sans-serif;
//   h4 {
//     margin-bottom: 0;
//     text-transform: uppercase;
//   }
// }

.main-octopus-content {
  h4 {
    margin-bottom: 0;
  }
}

.div-month-survey {
  .p-overlaypanel-content {
    padding: 0 !important;
  }

  .p-datepicker {
    border: unset !important;
  }
}

.detail-maps {
  .dots-days {
    display: inline-block;
    width: 7px;
    margin-right: 10px;
    height: 7px;
    border-radius: 100%;
    background-color: #f3941f;
  }
}

.filter-detail-survey {
  background-color: #f8f8f8;
  padding: 20px 30px;

  .p-dropdown {
    border-radius: 10px !important;
    border: unset !important;
  }

  span,
  b {
    font-size: 14px !important;
    // font-family: 'Roboto'!important;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif;
}

.detail-survey {
  .nav-link {
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid #ebebeb;
    color: #000000;
    font-weight: 500;
    padding: 10px;
  }

  .dots-days {
    display: inline-block;
    width: 7px;
    margin-right: 10px;
    height: 7px;
    border-radius: 100%;
    background-color: #f3941f;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    background-color: #fff !important;
    color: #2d9cdb !important;
    border: unset !important;
    border-bottom: 1px solid #ebebeb;
    border-radius: unset !important;
  }

  .list-tab {
    width: 300px;
  }

  .list-content-tab {
    width: calc(100% - 300px);
    min-height: 300px;
    background-color: #fff;

    .tab-pane {
      height: 100%;
      border: unset;
    }
    .tab-pane.active {
      background-color: transparent !important;
      color: #000 !important;
      border: 1px solid transparent;
    }
  }
}

.carousel-item.active {
  background-color: transparent !important;
  color: #2d9cdb !important;
  border: unset !important;
  border-bottom: 1px solid #ebebeb;
  border-radius: unset !important;
}

.tab-content {
  padding: 10px;
}

.tbl-detail-survey td {
  border: 1.5px solid #b7ddf0 !important;
}

.menu-octopus {
  width: 206px;
  min-height: 100vh;
  background-color: #fec77c;
  position: relative;

  .logo-octopus {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fec77c;
  }

  .logo-octopus > img {
    height: 85px;
    object-fit: contain;
  }

  .btn-login-siderbar {
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 5px;
    right: 5px;

    button {
      background-color: unset;
      border: unset;
    }

    button > img {
      transform: rotate(180deg);
    }
  }
}

.page-header {
  padding: 30px 60px 30px 15px;
  background-color: #fff;
  position: relative;

  .container-fluid {
    height: 100%;
    position: relative;
  }

  .header-dropdown .dropdown-toggle {
    background: #fff;
    color: #000;
    border: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 14px;
  }
}

.sidenav {
  margin-top: 52px;
  height: calc(100% - 210px);
  width: 206px;
  // transition-duration: 0.25s;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fec77c;
  overflow-x: hidden;
  position: relative;

  .menu-item {
    padding: 4px 0px 4px 27px;
    cursor: pointer;
    width: 100%;
    background-color: transparent;
    border: unset;
    box-shadow: unset;
    outline: unset;
    text-align: left;
    font-size: 12px !important;
    line-height: 16px;
    min-height: 32px;
    font-weight: 500 !important;

    span {
      margin-left: 5px;
      font-size: 12px !important;
      font-family: 'Roboto', sans-serif;
      font-weight: bolder;
    }
  }

  .menu-item.active {
    background-color: #ffefce;
    border-left: 3px solid #000;
    padding-left: 23px !important;
  }

  .dropdown-btn {
    padding: 10px 10px 10px 27px;
    text-decoration: none;
    color: #000;
    border: none;
    background: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    font-size: 12px;
    text-align: left;
    height: 40px;

    img {
      margin-right: 5px;
      pointer-events: none;
    }

    .fa-chevron-down {
      transform: rotate(0deg);
      transition-duration: 0.25s;
      float: right;
      margin-top: 5px;
      pointer-events: none;
      // display: none;
    }

    .text-menu {
      margin-left: 5px !important;
      pointer-events: none;
      font-family: 'Roboto', sans-serif;
      font-size: 12px !important;
      font-weight: bolder;
    }
  }

  .menu-item:not(.active):hover {
    background-color: #ffce8a;
  }

  .dropdown-btn:not(.active):hover {
    background-color: #ffce8a;

    // .fa-chevron-down {
    //     display: block;
    // }
  }

  .sidenav button:hover,
  .dropdown-btn:hover {
    color: #000;
  }

  // .dropdown-btn:hover{
  //     pointer-events: none;
  // }

  .dropdown-container.active {
    .menu-item.active {
      // background-color: #ffefce;
      // border-left: 3px solid #000;
      padding-left: 55px !important;
    }
  }

  .dropdown-btn.active {
    background-color: #ffce8a;
    color: #000;

    .fa-chevron-down {
      transform: rotate(180deg);
      transition-duration: 0.25s;
    }
  }

  .menu-item {
    font-family: 'Roboto', sans-serif;
    font-weight: bolder;
    font-size: 12px;
    img {
      margin-right: 5px;
      pointer-events: none;
    }
  }

  .dropdown-container {
    display: none;
    background-color: #ffce8a;

    .menu-item {
      padding-left: 58px;
    }
  }

  .dropdown-container.active {
    display: block;
  }

  .fa-chevron-down {
    font-weight: 500;
  }
}

.main-body-content {
  z-index: 1;
  font-family: 'Roboto', sans-serif;
  // height: 100%;
  // position: relative;

  // overflow-x: hidden;

  .p-scrollpanel-content {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;
    width: 100%;
    padding: unset;
    position: relative;
  }

  .p-scrollpanel-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  .p-scrollpanel-content::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  .p-scrollpanel-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }

  .octopus-dataview {
    background-color: #fff;
    border-radius: 10px;
    padding-top: 20px;
    z-index: 1;
  }
}

.p-card {
  box-shadow: unset;
  border-radius: 10px;
}

.sec-404 {
  width: 100%;
  height: 100%;
  // background: #00e7dc;
  background: #c4c4c4;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;
  z-index: 99999999;

  h1 {
    font-family: "Nunito", sans-serif;
    color: #fff;
    text-align: center;
    padding: 2em 0 0;
    opacity: 0;
    font-size: 40px;
  }

  svg {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  button {
    z-index: 99999999;
    position: fixed;
    left: calc(50% - 78px);
    font-family: "Nunito", sans-serif;
    font-size: 24px;
    border: unset;
    background: unset;
  }

  button:hover {
    text-decoration: underline;
  }
}

.p-dataview .p-dataview-content {
  // padding: 10px !important;

  .card-company {
    padding: 30px 20px;
    cursor: pointer;
    min-height: 1200px;
  }
}

.main-layout {
  position: relative;
  // overflow-y: scroll;
  // overflow-x: hidden;

  .logo-octopus {
    // width: 70px;
    left: 78px;
    top: 44px;
    position: absolute;
  }

  .logo-octopus > img {
    height: 90px;
    object-fit: contain;
  }

  .nav-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    padding: 5px 15px;
    border: none;
    background-color: unset;
    z-index: 999;

    .label {
      text-transform: capitalize;
      // font-weight: bold;
      font-family: 'Roboto', sans-serif;
    }
  }

  .nav-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 80px;
    margin-right: 40px;
    margin-top: 52px;
  }

  .p-scrollpanel-bar-x {
    display: none !important;
  }

  .p-scrollpanel-content {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100vh;
    width: 100%;
    padding: unset;
  }

  .p-scrollpanel-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  .p-scrollpanel-content::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  .p-scrollpanel-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }

  .page-body-content {
    min-height: 100vh;
    padding: 10px;
  }

  .custom-scrolltop {
    margin-top: -40px;
  }

  // .footer-octopus li {
  //     list-style: none;
  // }
}

.link-footer {
  color: #000;
  font-weight: 400;
}

.form-contact {
  .p-card {
    box-shadow: unset;
  }

  .p-inputtext,
  .p-dropdown {
    border-radius: 10px;
    height: 49.2px;

    .p-dropdown-label {
      display: flex;
      align-items: center;
    }
  }
}

.full-height-message {
  height: 288px !important;
}

.captchaContainer {
  display: flex;
  align-items: center;

  .retryButton {
    border: none;
    margin: 0 0 0 16px;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    outline: none;
    text-align: inherit;
  }
  // display: flex;
  // align-items: center;

  // canvas {
  //     width: calc(100% - 40px);
  //     // height: 50px;
  // }

  // .retryButton {
  //     width: 40px;
  //     border: none;
  //     margin: 0 0 0 16px;
  //     padding: 0;
  //     width: auto;
  //     overflow: visible;
  //     background: transparent;
  //     color: inherit;
  //     font: inherit;
  //     line-height: normal;
  //     -webkit-font-smoothing: inherit;
  //     -moz-osx-font-smoothing: inherit;
  //     -webkit-appearance: none;
  //     outline: none;
  //     text-align: inherit;
  // }
}

.upload-image-about {
  border-radius: 10px;

  .p-inputgroup-addon:first-child,
  .p-inputgroup button:first-child,
  .p-inputgroup input:first-child,
  .p-inputgroup > .p-inputwrapper:first-child,
  .p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
    background-color: unset !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    border-top: 1px solid #dadada !important;
    border-left: 1px solid #dadada !important;
    border-bottom: 1px solid #dadada !important;
  }

  .p-inputtext,
  .p-inputtext:disabled {
    border-left: unset;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-top: 1px solid #dadada !important;
    border-right: 1px solid #dadada !important;
    border-bottom: 1px solid #dfc5c5 !important;
  }
}

.dialog-about {
  .p-dialog-content {
    max-height: 700px;
  }

  .p-dialog-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  .p-dialog-content::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  .p-dialog-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}

.menu-home-octopus {
  // height: 132px;
  padding: 44px 58px !important;
  z-index: 99999;
  background-color: #fff !important;

  .nav-link {
    text-transform: capitalize;
    // font-weight: 700;
    color: #000 !important;
    font-family: 'Roboto', sans-serif;
    padding: 5px 15px !important;
    position: relative;
  }

  .dot-menu {
    display: none;
    width: 5px;
    height: 5px;
    background-color: #f3941f;
    border-radius: 100%;
    position: absolute;
    bottom: 5px;
  }

  .item-menu-desktop.active {
    // color: #f3941f;
    // font-weight: bolder!important;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

    .dot-menu {
      display: block;
      width: 5px;
      height: 5px;
      background-color: #f3941f;
      border-radius: 100%;
      position: absolute;
      bottom: 5px;
    }
  }

  .item-menu-desktop {
    padding: 0 0 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.menu-home-octopus-mb {
  // padding: 51px 19px 8px 19px !important;
  padding: 19px 19px 8px 19px !important;
}

.nav-button-mb {
  border: transparent !important;
  background: transparent !important;
  box-shadow: none !important;
  outline: none !important;
}

.text-menu-mb {
  font-size: 26px;
  color: #000 !important;
  font-weight: bold;
  cursor: pointer;
}

footer {
  padding: 15px 30px;
  background-color: #fffbf4;
}

.logo-team-software {
  height: 84px;
  width: 250px;
}

.logo-team-software img {
  padding: 10px 15px;
  height: 84px;
}

.info-footer {
  width: calc(100% - 334px);

  .footer-octopus {
    width: 33.333%;
  }

  .footer-octopus li {
    min-height: 25px;
    list-style: none;
  }
}

.qr-code {
  width: 84px;
  height: 84px;
}

@media screen and (max-width: 992px) {
  footer {
    padding: 15px 21px;
    background-color: #fffbf4;
  }

  .logo-team-software {
    width: 100%;
  }

  .logo-team-software img {
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .info-footer {
    width: 100%;
    flex-direction: column;

    .footer-octopus {
      padding-top: 10px;
      width: 100%;
      margin: 0;
      padding-inline-start: unset;
    }
  }

  .qr-code {
    width: 100% !important;
    min-height: 95px;
  }

  .qr-code > img {
    height: 84px;
    object-fit: contain;
  }
}

/*content-about*/

.content-about {
  width: 100%;
  min-height: 100%;
  padding: 8px 45px;
}

.sticky-about,
.feature-about,
.list-about {
  padding: 0px;
}

/*content-stickey*/

.img-sticky {
  width: 60%;
  padding: 0px 15px;
}

.content-stickey {
  width: 40%;
  padding: 0px 30px;

  .stickey-title {
    margin-top: 20px;
    font-size: 38px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
  }

  .stickey-description {
    margin-top: 18px;
    font-size: 18px;
    font-weight: 400;
    color: #414042;
    font-family: 'Roboto', sans-serif;
  }

  .stickey-times {
    margin-top: 20px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #414042;
    text-transform: capitalize;
  }

  .short-description {
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    height: 100%;
  }

  .btn-readmore {
    margin-top: 30px;
    display: block;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    color: #000;
  }
}

.img-sticky > img {
  width: 100%;
  height: 485px;
  object-fit: cover;
  border-radius: 15px;
}

.future-box {
  margin-top: 22px;
  width: 50%;
  padding: 0px 15px;
}

.future-img {
  width: 100%;
}

.future-img > img {
  width: 100%;
  height: 370px;
  object-fit: cover;
  border-radius: 15px;
}

.future-times {
  font-size: 14px;
  font-weight: 500;
  color: #828282;
  text-transform: capitalize;
  margin-top: 13px;
}

.future-title {
  margin-top: 17px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
}

.item-about-img {
  width: 647px;
  padding: 0px 15px;
}

.item-about-img > img {
  height: 256px;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.item-about-content {
  width: calc(100% - 647px);
  padding: 0px 15px;

  .items-times {
    font-size: 14px;
    font-weight: 500;
    color: rgb(130, 130, 130);
    text-transform: capitalize;
  }

  .items-title {
    margin-top: 17px;
    font-size: 28px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
  }

  .items-description {
    margin-top: 17px;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
  }
}

/*detail-about*/
.detail-about-title {
  // margin-top: 70px;
  font-size: 50px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

.detail-about-times {
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(130, 130, 130);
  text-transform: capitalize;
}

.detail-about-img {
  margin-top: 36px;
}

.detail-about-img > img {
  width: 100%;
  height: 485px;
  object-fit: cover;
}

.detail-about-description {
  margin-top: 36px;
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  text-transform: capitalize;
}
.content-detail-about {
  margin-top: 42px;
}

.content-detail-about img {
  width: 100% !important;
  object-fit: contain !important;
}

.heading-thin {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  font-size: 41px;
}

.heading-bold {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 53px;
}

.footer-link {
  text-decoration: underline;
  font-weight: bold;
  color: #000;
  cursor: pointer;
}

.item-client {
  width: 20%;
  padding: 30px;
}

.contact-page {
  min-height: 100vh;
  background: #fdb64c;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 33.3vh 0;

  .text-show {
    background: #fffbf4;
    height: 33.3vh;
    width: 100%;
    display: flex;
  }

  .contact-title {
    font-size: 56px;
    font-weight: bold;
  }

  .contact-description {
    font-size: 20px;
    font-weight: 100;
  }

  .contact-form {
    position: absolute;
    width: 60%;
    top: 10vh;
    height: 80vh;
    background: #fff;
    right: 45px;
    border-radius: 30px;
    padding: 30px;
  }

  .title-form {
    font-weight: bold;
    font-size: 18px;
  }

  span {
    font-size: 14px;
  }

  .form-control {
    height: 49.2px !important;
    border-radius: 10px !important;

    font-size: 14px;
  }

  .p-dropdown {
    height: 49.2px !important;
    border-radius: 10px !important;
    display: flex;
    align-items: center;
    outline: none;
    box-shadow: unset;
  }

  .input-group-prepend {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1px solid #ced4da;
    border-right: unset;
    padding: 0px 10px 0px 15px;
  }

  .input-group {
    .form-control {
      border-left: unset;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: unset !important;
      border-bottom-left-radius: unset !important;
    }

    .form-control:focus {
      border: 1px solid #ced4da;
      outline: none;
      box-shadow: unset;
      border-left: unset;
      // color: #F3941F
    }
  }

  textarea {
    width: 100%;
    height: 142px !important;
    border-radius: 10px;
    padding: 1rem;
    border: 1px solid #ced4da;
  }

  textarea:focus {
    border: 1px solid #ced4da;
    outline: none;
    box-shadow: unset;
    // color: #F3941F
  }
}

@media screen and (max-width: 992px) {
  .item-client {
    width: 50%;
    padding: 30px;
  }

  .landing-page-mb {
    height: calc(100vh - 87px);
    background-image: url("/img/banner/mb-update-min.png");
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 0px;

    .heading-thin {
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 30px;
    }

    .heading-bold {
      font-family: 'Roboto', sans-serif;
      font-weight: bold;
      font-size: 38px;
    }
  }

  .contact-page {
    // min-height: 100vh;
    background: #fdb64c;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 15px 30px 15px;

    .container-contact {
      // height: 90vh;
      background: #fff;
      padding: 10px 15px;
    }
  }

  .landing-page-mb-1 {
    min-height: 100px;
    margin-top: 0px;

    .heading-bold {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-weight: bold;
      font-size: 38px;
    }

    .text-desciption li {
      font-size: 18px;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      padding: 15px 0px;
      list-style: none;
    }

    ul.text-desciptions {
      padding-inline-start: 0px !important;
    }

    .text-desciptions li {
      font-size: 18px;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      padding: 15px 0px;
      list-style: none;
    }
  }

  .detail-about-title {
    margin-top: 19px;
    font-size: 32px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
  }

  .detail-about-times {
    margin-top: 19px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(130, 130, 130);
    text-transform: capitalize;
  }

  .detail-about-description {
    margin-top: 36px;
    font-size: 18px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    text-transform: capitalize;
  }

  .future-times {
    font-size: 18px;
    font-weight: 500;
    color: #828282;
    text-transform: capitalize;
    margin-top: 13px;
  }

  .future-title {
    margin-top: 17px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
  }

  .img-sticky {
    width: 100%;
    padding: 0px 21px;
  }

  .img-sticky > img {
    width: 100%;
    height: 398px;
    object-fit: cover;
    border-radius: 15px;
  }

  .content-stickey {
    width: 100%;
    padding: 0px 21px;

    .stickey-title {
      margin-top: 20px;
      font-size: 32px;
      font-weight: 500;
    }

    .short-description {
      border-top: unset;
      border-bottom: unset;
      height: 100%;
    }
  }

  .future-box {
    margin-top: 22px;
    width: 100%;
    padding: 0px 15px;
  }

  .content-about {
    width: 100%;
    min-height: 100%;
    padding: 8px 21px;
  }

  .item-about-content {
    width: 100%;
    padding: 0px 15px;

    .items-times {
      margin-top: 32px;
      font-size: 14px;
      font-weight: 500;
      color: rgb(130, 130, 130);
      text-transform: capitalize;
    }

    .items-title {
      margin-top: 17px;
      font-size: 26px;
      font-weight: 500;
      font-family: 'Roboto', sans-serif;
    }

    .items-description {
      margin-top: 17px;
      font-size: 18px;
      font-weight: 400;
      font-family: 'Roboto', sans-serif;
    }
  }
}

.vjs-big-play-button {
  width: 55px !important;
  height: 55px !important;
  line-height: 55px !important;
  border-radius: 100% !important;
  background-color: #fff !important;
  color: #f3941f !important;
  font-size: 40px !important;
  box-shadow: 0px 0px 0px 10px rgba(253, 182, 76, 0.7) !important;
}

.custom-checkbox .p-checkbox-box {
  border: 1px solid #22C55E !important;
  border-radius: 100% !important;
}

.custom-checkbox .p-checkbox-box.p-highlight {
  background: #92df82 !important;
}

.custom-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #409d2d !important;
}

.create-about {
  .p-inputtext {
    border-radius: 10px !important;
    height: 45px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    padding: 10px 20px;
  }

  .p-inputtextarea {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    height: 90px;
  }

  .p-chip {
    margin: 2.5px;
    background: #ebf8ff;
    color: #2d9cdb;
  }

  .p-chip-text {
    margin-top: 0.2145rem !important;
    margin-bottom: 0.2145rem !important;
  }

  .p-chip-remove-icon {
    font-size: 9px;
    color: #000;
    // pointer-events: none!important;
  }
}

.btn-image-upload {
  outline: unset;
  border: 1px solid #f8f8f8;
  background: #f8f8f8;
  padding: 10px 20px;
  display: flex;
  border-radius: 10px;
  align-items: center;
}

.image-upload-preview {
  position: relative;

  .p-button.p-button-icon-only {
    width: unset !important;
  }

  .btn-choose-file {
    position: absolute;
    bottom: 15px;
    right: 15px;
    background-color: #fff;
    opacity: 0.5;
    border-color: transparent;
    color: #000;
    padding: 0px 2.5px !important;
  }
}

.image-upload-preview > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #ebebeb;
}

.btn-about {
  width: 282px;
  height: 45px;
}

.btn-del {
  background: #260807 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 500 !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.detail-survey {
  font-family: 'Roboto', sans-serif;
  position: relative;

  b {
    font-family: 'Roboto', sans-serif;
  }

  .bp3-tab-list,
  .bp3-tab-indicator-wrapper {
    width: 232px !important;
  }

  .bp3-tabs.bp3-vertical > .bp3-tab-list {
    background-color: #f8f8f8 !important;
  }

  .bp3-tab-indicator-wrapper ~ .bp3-tab {
    background-color: #f8f8f8 !important;
    border-top: 1px solid #ebebeb !important;
    font-size: 14px;
    font-size: 400;
    font-family: 'Roboto', sans-serif;
    min-height: 58px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: unset !important;
  }

  .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab[aria-selected="true"] {
    background-color: #fff !important;
    color: #2d9cdb !important;
    border-top: unset !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    min-height: 58px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: unset !important;
  }

  .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab[aria-selected="true"]:focus {
    outline: unset !important;
    box-shadow: unset !important;
  }

  .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab {
    white-space: pre-wrap;
    text-align: center;
    // height: 58px!important;
  }

  .bp3-tabs.bp3-vertical > .bp3-tab-panel {
    width: 100%;
    background-color: #fff !important;
    padding: 5px 20px;

    .content-image {
      position: relative;
      border-radius: 5px;
      width: 100% !important;
      height: 100% !important;
      margin-top: 5px;
      margin-bottom: 5px;

      .desc-image {
        width: 100%;
        height: 21.91px;
        text-align: center;
        position: absolute;
        bottom: 0;
        background-color: #fff;
        opacity: 0.7;
        color: #ff5151;
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  // .table thead th {
  //   border: 1px solid #b7ddf0;
  //   font-family: 'Roboto', sans-serif;
  //   font-size: 14px;
  // }
  // .table td,
  // .table th {
  //   border: 1px solid #b7ddf0;
  //   font-family: 'Roboto', sans-serif;
  //   font-size: 14px;
  // }

  .content-image {
    position: relative;
    border-radius: 5px;
    width: 41px;
    height: 30px;
  }
}

.filter-button,
.filter-button:focus-visible,
.filter-button:focus {
  outline: unset !important;
  box-shadow: unset !important;
  overflow: hidden !important;
}

//define-component-button

.btn-def-shadow {
  width: auto;
  height: 100%;
  position: relative;
  display: inline-block;

  .btn-def {
    width: 100%;
    height: 100%;
    padding: 12px 40px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    margin-right: 12px;
  }

  .btn-def.btn-def-warning {
    background: #fcb64b;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

    border-radius: 10px;
    border: unset;
    color: #fff;
    z-index: 11000;
  }

  .btn-def.btn-def-danger {
    background: #260807;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

    border-radius: 10px;
    border: unset;
    color: #fff;
    z-index: 11000;
  }

  .btn-def.btn-def-success {
    background: #22C55E;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

    border-radius: 10px;
    border: unset;
    color: #fff;
    z-index: 11000;
  }

  .btn-def.btn-def-primary {
    background: #2d9cdb;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

    border-radius: 10px;
    border: unset;
    color: #fff;
    z-index: 11000;
  }

  .btn-def.btn-def-dark {
    background: #000;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

    border-radius: 10px;
    border: unset;
    color: #fff;
    z-index: 11000;
  }

  .btn-def.btn-def-assign {
    background: transparent;
    // border: 1px solid #2D9CDB!important;
    outline: unset;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

    border-radius: 10px;
    border: unset;
    color: #2d9cdb;
    z-index: 11000;
  }

  .btn-def.btn-def-info {
    background: #2d9cdb;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

    border-radius: 10px;
    border: unset;
    color: #fff;
    z-index: 11000;
  }

  .btn-def.btn-def-import {
    background: #7d6cd3;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

    border-radius: 10px;
    border: unset;
    color: #fff;
    z-index: 11000;
  }

  .btn-def.btn-def-role {
    background: #e3eff9;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    border-radius: 10px;
    border: unset;
    color: #1a74bc;
    z-index: 11000;
  }

  .btn-def.btn-def-role:hover {
    background: #2d9cdb;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;

    border-radius: 10px;
    border: unset;
    color: #ffffff;
    z-index: 11000;
  }

  .btn-def.btn-def-default {
    background: #ededed !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;

    border-radius: 10px;
    border: unset;
    color: #000;
    z-index: 11000;
  }
}

.btn-preview {
  width: 29px;
  height: 28px;
  background: url("/img/icons/preview.svg") no-repeat;
  border: unset;
  box-shadow: unset;
}

.btn-preview:hover {
  width: 31px;
  height: 30px;
  border: unset;
  box-shadow: unset;
  background: url("/img/icons/preview-alt.svg") no-repeat;
}

.btn-def-shadow.disabled,
.btn-def.disabled {
  background: #a4a4a4 !important;
  border-radius: 10px;
  border: unset;
  color: #fff;
  z-index: 11000;
  opacity: 0.5;

  pointer-events: none !important;
  cursor: not-allowed !important;
}

.btn-def-shadow.btn-shadow-warning {
  min-height: 41px;
  min-width: 166px;
  // width: 100%;
  display: inline-block;

  .shadow-line {
    position: absolute;
    left: 3.61%;
    right: 1.2%;
    // top: 80.49%;
    bottom: 0%;
    height: 8px;
    background: linear-gradient(
        89.99deg,
        #fc5e1a 0%,
        rgba(255, 255, 255, 0) 99.99%
      ),
      #e4801f;
    opacity: 0.6;
    filter: blur(18px);
    border-radius: 25px;
    z-index: 99;
  }
}

.btn-def-shadow.btn-shadow-success {
  min-height: 41px;
  min-width: 166px;
  // width: 100%;
  display: inline-block;

  .shadow-line {
    /* Rectangle 72 */

    position: absolute;
    left: 0%;
    right: 0%;
    top: 82.5%;
    bottom: 2.5%;

    background: #22C55E;
    filter: blur(18px);
  }
}

.btn-def-shadow.btn-shadow-warning.btn-loading {
  pointer-events: none !important;
  cursor: not-allowed;

  .btn-def.btn-def-warning {
    background: #a4a4a4;
    border-radius: 10px;
    border: unset;
    color: #fff;
    z-index: 11000;
    opacity: 0.5;

    pointer-events: none !important;
    cursor: not-allowed;
  }

  .loading-icon {
    fill: #fcb64b !important;
  }
}

.btn-def-shadow.btn-shadow-danger.btn-loading {
  pointer-events: none !important;
  cursor: not-allowed;

  .btn-def.btn-def-danger {
    background: #a4a4a4;
    border-radius: 10px;
    border: unset;
    color: #fff;
    z-index: 11000;
    opacity: 0.5;

    pointer-events: none !important;
    cursor: not-allowed;
  }

  .loading-icon {
    fill: #260807 !important;
  }
}

.btn-def-shadow.btn-shadow-success.btn-loading {
  pointer-events: none !important;
  cursor: not-allowed;

  .btn-def.btn-def-success {
    background: #a4a4a4;
    border-radius: 10px;
    border: unset;
    color: #fff;
    z-index: 11000;
    opacity: 0.5;

    pointer-events: none !important;
    cursor: not-allowed;
  }

  .loading-icon {
    fill: #22C55E !important;
  }
}

.form-update-info {
  font-family: 'Roboto', sans-serif;
  font-size: 14px !important;

  .form-label,
  .form-control {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif;
  }

  .alert-info {
    font-family: 'Roboto', sans-serif;
  }

  .p-message-summary,
  .p-dropdown-label,
  .p-message-detail {
    font-family: 'Roboto', sans-serif;
    font-size: 14px !important;
  }

  .bp3-input,
  .p-dropdown,
  .form-control {
    height: 45px;
    border-radius: 10px;
    display: flex;
    font-size: 14px;
    align-items: center;
  }

  .p-steps-item.p-steps-current .p-menuitem-link,
  .p-steps .p-steps-item .p-menuitem-link {
    cursor: default;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
  }
}

.swal2-html-container {
  padding-bottom: 30px !important;
  font-family: 'Roboto', sans-serif;
}

.swal2-modal {
  box-shadow: 0px 0px 0px 10px rgba(182, 182, 182, 0.7) !important;
  border-radius: 10px !important;
  border: unset !important;
}

.swal2-footer {
  margin-top: -20px !important;
  border-top: unset !important;
}

.d-update-status-contact {
  font-family: 'Roboto', sans-serif;

  .p-dialog-title {
    font-size: 24px !important;
    font-weight: bold;
    text-align: center !important;
    margin-top: 39px !important;
  }

  .p-dropdown-label {
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 400 !important;
  }
}

.p-dialog-mask .p-dialog-visible {
  z-index: 2012 !important;
  border-radius: 10px !important;
  background-color: #fff !important;
  border-color: unset !important;
}

.p-dropdown-panel {
  z-index: 3012 !important;
}

// .p-dialog {
//   border-radius: 10px !important;
//   border: unset !important;

//   .container {
//     font-family: 'Roboto', sans-serif;
//   }

//   .p-dialog-header {
//     border-top-left-radius: 10px !important;
//     border-top-right-radius: 10px !important;
//   }

//   .p-dialog-content {
//     border-bottom-left-radius: 10px !important;
//     border-bottom-right-radius: 10px !important;
//   }
// }

.assign-company {
  // span{
  //     margin-bottom: unset!important;
  // }

  .p-dropdown {
    border-radius: 10px !important;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: #000;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 5px 0px 5px 10px;

    .p-dropdown-label {
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
      color: #000;
    }
  }

  .input-group {
    border: 1px solid #c4c4c4;
    height: 45px;
    border-radius: 10px;

    .form-control {
      border: unset;
      height: 43px;
      margin-right: 1px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
      color: #000;
      border-radius: 10px;
      font-weight: 400;
    }
  }

  .rc-color-picker-wrap {
    padding: 0 !important;
    margin: 0 !important;

    .rc-color-picker-trigger {
      width: 51px !important;
      height: 43px !important;
      padding: 0px !important;
      margin: 0 !important;
      border-radius: 10px !important;
      border: unset;
    }
  }
}

.info-products {
  font-size: 14px !important;
}

.ai-dialog {
  .btn-upload {
    border-radius: 10px;
    border: 1px dashed #c4c4c4;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-control {
    height: 45px;
    border-color: #c4c4c4;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    border-radius: 10px;
  }

  .content-upload {
    // min-height: 200px;
    background-color: #f8f8f8;
    margin-top: 13px;
    margin-left: -16px !important;
    margin-right: -16px !important;
    padding: 21px 78px 20px 78px;
  }
}

.p-dialog-title {
  // text-align: center!important;
  margin-top: 39px !important;
  font-size: 24px !important;
  color: #0c0c0c;
  font-family: 'Roboto', sans-serif;
  font-weight: 500 !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  font-weight: bold !important;
  font-family: 'Roboto', sans-serif;
  font-size: 14px !important;
}

.company-role {
  .form-control {
    height: 45px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500 !important;
    color: #000;
    border-radius: 10px;
    // border: unset!important;
  }

  .p-multiselect,
  .p-dropdown {
    height: 45px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500 !important;
    font-size: 14px;
    color: #000 !important;
    padding: 5px 0px 5px 13.48px;
  }

  .p-multiselect-items span {
    font-family: 'Roboto', sans-serif;
    font-weight: 500 !important;
    font-size: 14px;
  }

  .p-multiselect-label,
  .p-dropdown-label {
    font-family: 'Roboto', sans-serif;
    font-weight: 500 !important;
    color: #000 !important;
    font-size: 14px;
  }

  .p-dialog-content {
    border-bottom-left-radius: unset !important;
    border-bottom-right-radius: unset !important;
  }

  .p-dialog-footer {
    border-top: unset !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  .table-product .p-datatable-tbody > tr > td {
    padding: 5px;
    font-size: 14px;
    overflow-wrap: break-word;
    font-family: 'Roboto', sans-serif;
  }

  .table-product .p-datatable-thead > tr > th {
    padding: 5px;
    font-size: 14px;
    overflow-wrap: break-word;
    font-family: 'Roboto', sans-serif;
  }

  .table-all .p-datatable-tbody > tr > td {
    border: unset !important;
    border-bottom: 1px dashed #c4c4c4 !important;
  }

  .table-all .p-datatable-thead > tr > th {
    border: unset !important;
    border-bottom: 1px dashed #fdb64c !important;
  }

  .btn-group-cust-content {
    width: calc(100% - 275px);

    .form-control,
    .p-dropdown {
      border: 1px solid #c4c4c4 !important;
    }
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #44a434 !important;
  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #fff !important;
  }

  .p-inputswitch .p-inputswitch-slider {
    background-color: #c4c4c4 !important;
  }

  .p-inputswitch .p-inputswitch-slider:before {
    background: #fff !important;
  }

  .bp3-input {
    height: 45px;
    border-radius: 10px;
  }

  .bp3-tabs .bp3-tab {
    font-size: 14px !important;
  }

  .bp3-tabs.bp3-vertical > .bp3-tab-list {
    background-color: #f8f8f8 !important;
  }

  .bp3-tab-indicator-wrapper ~ .bp3-tab {
    background-color: #f8f8f8 !important;
    border-top: 1px solid #ebebeb !important;
    font-size: 14px;
    font-size: 400;
    font-family: 'Roboto', sans-serif;
    min-height: 58px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: unset !important;
  }

  .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab[aria-selected="true"] {
    background-color: #fff !important;
    color: #2d9cdb !important;
    border-top: unset !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    min-height: 58px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: unset !important;
  }

  .bp3-tabs.bp3-vertical > .bp3-tab-list .bp3-tab[aria-selected="true"]:focus {
    outline: unset !important;
    box-shadow: unset !important;
  }

  .bp3-tabs.bp3-vertical > .bp3-tab-panel {
    width: 100%;
    background-color: #fff !important;
    padding: 5px 20px;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #fcb64b;
    background: #fff;
  }

  .p-radiobutton-box.p-highlight .p-radiobutton-icon {
    border-color: #fcb64b;
    background: #fcb64b;
  }

  .p-calendar-w-btn {
    border-radius: 10px !important;
    border: 1px solid #ced4da;
  }

  .p-calendar-w-btn .p-button {
    border-right: unset !important;
    border-top: unset !important;
    border-bottom: unset !important;
  }

  .filter-component {
    background-color: #f8f8f8 !important;
    margin-top: 10px;
    border: unset !important;
    position: relative;
    padding: 10px 20px 30px 20px !important;
    margin-bottom: unset !important;
    border-radius: 10px !important;
  }

  .p-datatable .p-datatable-thead > tr > th {
    border: unset;
    border-bottom: 1px dashed #fdb64c;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #000 !important;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    border: unset;
    border-bottom: 1px dashed #c4c4c4;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #000 !important;
    width: 100%;
  }

  .image-indentity {
    width: auto;
    .lightbox-container {
      width: 238px !important;
    }

    .img-dev {
      width: 238px !important;
    }

    .btn-zoom {
    }
  }
}

hr.divider-role {
  border-top: 1px dashed #c4c4c4;
  margin-top: 26px !important;
  margin-bottom: 18px;
}

hr.divider-roles {
  border-top: 1px dashed #c4c4c4;
  margin-top: 0 !important;
  margin-bottom: 0;
}

input[type="image"]:disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: not-allowed !important;
}

.p-button-label {
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

.swal2-container {
  z-index: 9999 !important;
}

.button-noset {
  border: unset;
  background-color: unset;
  outline: unset;
  box-shadow: unset;
}

.bp3-portal {
  z-index: 3013;
}

.div-image-info-user {
  width: calc(100% - 173px);
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-left: 26px !important;
  padding-right: 26px !important;

  .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check:before {
    top: 9px;
    left: 3px;
  }
}

.store-import {
  .p-accordion .p-accordion-tab:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    background: #ebebeb;
    color: #828282;
    border-color: #ebebeb;
    font-weight: 500;
    padding: 10px;
    border-radius: 10px !important;
  }

  .p-dropdown-label,
  .p-dropdown-item,
  .p-dropdown {
    font-size: 14px !important;
    border-color: transparent;
    border-color: #ebebeb;
    // background-color: ;
  }

  .p-dropdown-label:hover,
  .p-dropdown-item:hover,
  .p-dropdown:hover {
    font-size: 14px !important;
    // border-color: transparent;
    border-color: #ebebeb;
    // background-color: ;
  }

  .p-dropdown,
  .p-inputtext {
    border-radius: 10px;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link:hover {
    background: #fff !important;
    color: #828282 !important;
    border-color: #ebebeb !important;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link:focus {
    outline: unset !important;
    box-shadow: unset !important;
  }

  .p-accordion .p-accordion-tab {
    margin-bottom: 10px;
    box-shadow: unset;
    border-radius: 10px;
  }

  .p-accordion-tab-active {
    border: 1px solid #ebebeb;
    border-radius: 10px;

    .p-accordion-header-link {
      border-left: unset !important;
      border-right: unset !important;
      border-top: unset !important;
    }

    .p-accordion-content {
      border: unset !important;
      border-bottom-left-radius: 10px !important;
      border-bottom-right-radius: 10px !important;
    }
  }

  .header-accordion {
    width: calc(100% - 32px);
    padding: 0 10px;

    .mail-label {
      color: #000;
    }

    .short-desc {
      white-space: nowrap;
      width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      color: #000;
    }

    .name-from {
      font-size: 12px;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      color: #000;
    }
  }

  .p-accordion
    .p-accordion-header:not(.p-disabled).p-highlight
    .p-accordion-header-link {
    background: #fff;
    color: #e4801f;
    font-weight: 500;
  }

  .p-accordion-header-text {
    width: 100%;
    margin-right: 10px;
  }

  .p-accordion-content {
    font-size: 14px;
  }

  .form-control {
    height: 45px;
    border-radius: 10px;
  }
}

.overview-panel {
  .cus-calendar {
    border-radius: 10px !important;
    border: 1px solid #dadada !important;
    font-size: 14px !important;
    font-family: 'Roboto', sans-serif;

    .p-button-icon-only {
      border-top: unset !important;
      border-right: unset !important;
      border-bottom: unset !important;
    }

    // .p-calendar-w-btn:not(.p-disabled).p-inputwrapper-focus{
    //     border: 1px solid #dadada !important;
    //     box-shadow: unset!important;
    // }

    // .p-inputtext:enabled:focus{
    //     border: 1px solid #dadada !important;
    //     box-shadow: unset!important;
    // }
  }
}

.detail-product {
  .p-tabview .p-tabview-nav {
    background-color: #ebebeb;
    border-color: transparent;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: #ebebeb;
    color: #828282;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    padding: 17px 71px;
    border-color: transparent;
  }

  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: transparent;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #e4801f;
    border-color: transparent;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link:focus {
    background-color: #fff;
    border-color: transparent;
  }

  .p-tabview-nav-content {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.p-progressbar {
  height: 20px !important;
  border-radius: 2px !important;
  background-color: #ebebeb !important;
}

.p-progressbar .p-progressbar-label {
  display: block !important;
  line-height: 20px !important;
  color: #fff !important;
  font-size: 12px !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 400 !important;
}

.p-progressbar .p-progressbar-value {
  background-color: #000 !important;
}

.btn-nothing {
  background-color: transparent;
  border: transparent;
  outline: transparent;
  box-shadow: transparent;
}

.btn-nothing:hover {
  color: #106ba3;
}

.page-header {
  .header-company {
    border: unset !important;

    .p-dropdown-label {
      font-family: 'Roboto', sans-serif;
      font-size: 14px !important;
    }

    .p-inputtext {
      padding: 0.75rem 0.25rem;
    }
  }

  .p-connected-overlay-enter-done {
    top: 0 !important;
    left: 15px !important;
    transform-origin: unset !important;
    // box-shadow: unset!important;
    min-width: 200px !important;
  }

  .header-content-dropdown {
    font-family: 'Roboto', sans-serif;
    font-size: 14px !important;

    .p-dropdown-item {
      font-family: 'Roboto', sans-serif;
      font-size: 14px !important;
    }

    .text-desc {
      font-family: 'Roboto', sans-serif;
    }
  }
}

.select-days {
  position: relative;
  background-color: #fff;

  .btn-select-month {
    border: unset;
    background-color: #2d9cdb;
    color: #fff;
    font-weight: 700;
  }

  .list-days {
    width: calc(100% - 111px);

    .p-tabview-panels {
      display: none;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      padding: 30px 1.25rem;
      // border: unset;
    }

    // .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    //     border: unset!important;
    // }
  }
}

//////////////////light-box
.lightbox-btn {
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  background: none;
  border: 1px solid transparent;
  fill: white;
  opacity: 0.8;
  outline: none;
  margin: 2px 2px;
  padding: 0 0;
  -webkit-tap-highlight-color: #ffffff00;
}

.lightbox-btn:hover {
  opacity: 1;
  transition: opacity 200ms ease;
}

.lightbox-btn--ripple circle {
  fill: none;
}

.lightbox-btn--ripple:hover circle {
  transform: scale(3);
  transform-origin: 33% 33%;
  fill: #64646480;
  transition: transform 200ms ease;
}

html.lightbox-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.lightbox-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0px;
  left: 0px;
  z-index: 100;
}

.lightbox-btn-left,
.lightbox-btn-right,
.lightbox-btn-close,
.lightbox-title-content,
.lightbox-modifiers-box {
  transition: opacity 200ms ease;
}

@media (max-width: 767px) {
  .hide-controls .lightbox-btn-left,
  .hide-controls .lightbox-btn-right {
    opacity: 0;
    pointer-events: none;
  }
}

@media (max-height: 599px) {
  .hide-controls .lightbox-btn-close,
  .hide-controls .lightbox-title-content,
  .hide-controls .lightbox-modifiers-box {
    opacity: 0;
    pointer-events: none;
  }
}

.lightbox-btn-close {
  position: fixed;
  left: 0px;
  z-index: 2;
}

.lightbox-btn-right {
  position: absolute;
  top: 50%;
  margin-top: -1.5em;
  right: 0px;
}

.lightbox-btn-left {
  position: absolute;
  top: 50%;
  margin-top: -1.5em;
  left: 0px;
}

@media (min-width: 768px) {
  .lightbox-btn-right {
    padding-right: 10px;
  }

  .lightbox-btn-left {
    padding-left: 10px;
  }
}

.lightbox-title-content {
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  position: absolute;
  width: 100%;
  z-index: 1;
  bottom: 0;
  text-align: center;
  padding: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.42857143;
}

.lightbox-title {
  color: white;
  font-weight: 600;
  font-size: 16px;
}

.lightbox-description {
  color: #c5c5c5;
  font-size: 14px;
}

.lightbox-transition-image-appear {
  opacity: 0.1;
}

.lightbox-transition-image-appear.lightbox-transition-image-appear-active {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.lightbox-transition-image-enter-right {
  transform: translate(100%);
}

.lightbox-transition-image-enter-right.lightbox-transition-image-enter-right-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out;
}

.lightbox-transition-image-leave-right {
  transform: translate(0%);
}

.lightbox-transition-image-leave-right.lightbox-transition-image-leave-right-active {
  transform: translate(-100%);
  transition: transform 300ms ease-in-out;
}

.lightbox-transition-image-enter-left {
  transform: translate(-100%);
}

.lightbox-transition-image-enter-left.lightbox-transition-image-enter-left-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out;
}

.lightbox-transition-image-leave-left {
  transform: translate(0%);
}

.lightbox-transition-image-leave-left.lightbox-transition-image-leave-left-active {
  transform: translate(100%);
  transition: transform 300ms ease-in-out;
}

.lightbox-transition-image-enter {
  opacity: 0;
}

lightbox-transition-image-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.lightbox-transition-image-exit {
  opacity: 1;
}

.lightbox-transition-image-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.lightbox-content-center {
  width: 100%;
  height: 100%;
  position: absolute;
}

.lightbox-modifiers-box {
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 1.2em;
  position: absolute;
  width: 100%;
  z-index: 1;
}

@media (min-height: 600px) {
  .lightbox-modifiers-box {
    background-color: transparent;
  }
}

@media (min-width: 768px) {
  .lightbox-image-container {
    margin: 0 5em;
  }
}

.lightbox-image-container {
  overflow: hidden;
  height: calc(100% - 102px);
  margin-top: 40px;
}

.lightbox-image {
  height: 100%;
  background-repeat: no-repeat;
}

.lightbox-image.moving {
  transition: none;
}

.lightbox-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  fill: white;
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.lightbox-img-thumbnail {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  margin: 10px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .lightbox-img-thumbnail:hover {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }
}

.zoomImg {
  will-change: transform;
}

.lightbox-open {
  .lightbox-backdrop {
    z-index: 9999;
  }
}

.customize-modal {
  @media (min-width: 992px) {
    .p-sm-1,
    .p-sm-2,
    .p-sm-3,
    .p-sm-4,
    .p-sm-5,
    .p-sm-6,
    .p-sm-7,
    .p-sm-8,
    .p-sm-9,
    .p-sm-10,
    .p-sm-11,
    .p-sm-12,
    .p-md-1,
    .p-md-2,
    .p-md-3,
    .p-md-4,
    .p-md-5,
    .p-md-6,
    .p-md-7,
    .p-md-8,
    .p-md-9,
    .p-md-10,
    .p-md-11,
    .p-md-12,
    .p-lg-1,
    .p-lg-2,
    .p-lg-3,
    .p-lg-4,
    .p-lg-5,
    .p-lg-6,
    .p-lg-7,
    .p-lg-8,
    .p-lg-9,
    .p-lg-10,
    .p-lg-11,
    .p-lg-12,
    .p-xl-1,
    .p-xl-2,
    .p-xl-3,
    .p-xl-4,
    .p-xl-5,
    .p-xl-6,
    .p-xl-7,
    .p-xl-8,
    .p-xl-9,
    .p-xl-10,
    .p-xl-11,
    .p-xl-12 {
      padding: 0.5rem !important;
    }
  }

  .octopus-accordion {
    .p-accordion-tab {
      box-shadow: unset !important;
      // border: 1px solid #c4c4c4;
      // border-radius: 16px;

      // .p-accordion-content{
      //     border-top-right-radius: 16px!important;
      //     border-top-left-radius: 16px!important;
      // }
    }

    .p-accordion-tab:first-child {
      margin-bottom: -1px !important;
    }

    .p-accordion-tab ~ .p-accordion-tab {
      margin-bottom: -1px !important;
    }

    .p-accordion-tab.p-accordion-tab-active {
      margin-bottom: -1px !important;
    }

    .p-accordion-tab-active .p-accordion-content {
      border-top: 1px solid #c4c4c4;
      padding: 0.75rem;
    }

    .p-accordion-header-link {
      padding: 0.75rem 0.75rem !important;

      .p-accordion-toggle-icon {
        margin-right: unset !important;
      }
    }
  }
}

.custombar1 {
  .custom-header-h1 {
    .p-accordion-header-text {
      pointer-events: none !important;
    }
  }
  // .custom-header-h1{
  //     pointer-events: none;
  // }

  // .custom-header-h1 ~ .pi-chevron-right{
  //     pointer-events: initial;
  // }

  .p-accordion-header-link:hover {
    text-decoration: unset !important;
  }

  .hyperlink:hover {
    text-decoration: underline !important;
  }
}

.p-scrollpanel.custombar1 .p-scrollpanel-wrapper {
  border-right: 10px solid var(--surface-b);
}

.p-scrollpanel.custombar1 .p-scrollpanel-bar {
  background-color: var(--primary-color);
  opacity: 1;
  transition: background-color 0.2s;
}

.p-scrollpanel.custombar1 .p-scrollpanel-bar:hover {
  background-color: #007ad9;
}

.p-overlaypanel.cus-overlay {
  // box-shadow: unset!important;
  // border: 1px solid #c4c4c4!important;
  // box-shadow: 0 1px 1px -1px rgb(0, 0, 0), 0 1px -1px 1px rgb(0, 0, 0), 0 0px 0px 1px rgb(0, 0, 0)!important;
}

.cus-overlay:before {
  border-width: 10px;
  margin-left: -15px !important;
}

.cus-overlay::after {
  border-width: 8px;
  margin-left: -15px !important;
}

.cus-overlay::after,
.cus-overlay:before {
  bottom: 95% !important;
}

.page-hv1 {
  padding: 7px 0px 7px 15px;

  // .account-info.show{
  //     top: 0px;
  //     position: absolute;
  // }
}

// .pa-login.show{
//     transform: translate(0, 0)!important;
// }

.layout-page.expanded {
  .menu-octopus {
    width: 58px;
    // transition-duration: 0.25s;
    position: relative;

    .logo-octopus {
      height: 55px;
    }

    .logo-octopus > img {
      height: 50px;
      object-fit: contain;
    }

    .sidenav {
      width: 58px;
      // transition-duration: 0.25s;
      z-index: 999;

      .menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        .text-item {
          margin-left: 0px;
        }
      }

      .menu-item.active {
        background-color: #ffefce;
        border-left: 3px solid #000;
        padding-left: 0px !important;
      }

      .dropdown-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        min-height: 44px;
      }

      .dropdown-btn.active {
        background-color: #fec77c;
      }

      .dropdown-btn.active:hover + .dropdown-container,
      .dropdown-btn:hover + .dropdown-container {
        display: block;
      }

      .dropdown-btn.active + .dropdown-container:hover,
      .dropdown-btn + .dropdown-container:hover {
        display: block;
      }

      .dropdown-container {
        position: fixed;
        display: none;
        margin-left: 58px;
        margin-top: -44px;
        width: 206px;

        .menu-item {
          display: flex;
          justify-content: left;
          align-items: center;
          padding: 12px 20px;
        }

        .menu-item span {
          margin-left: 0px;
        }
      }

      .dropdown-container.active {
        .menu-item.active {
          padding-left: 20px !important;
        }
      }
    }

    .text-menu {
      display: none;
      // transition-duration: 0.25s;
    }

    .fa-chevron-down {
      display: none;
    }

    .btn-login-siderbar {
      display: block;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 5px;
      left: 0;
      right: 0;

      button {
        background-color: unset;
        border: unset;
      }

      .pi-angle-left {
        transform: rotate(-180deg);
      }
    }
  }

  .main-octopus-content {
    width: calc(100% - 58px) !important;
  }
}

.item-conditional {
  border: 1px solid #e8e8e8;
  padding: 10px 20px 0px;
  position: relative;
  border-radius: 10px;
  width: 100%;

  .btn-close {
    padding: 0px 2.5px;
    border: unset;
    background-color: rgb(200, 200, 200);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
  }
}

.detail-survey {
  font-family: 'Roboto', sans-serif;

  b {
    font-family: 'Roboto', sans-serif;
  }

  .bp4-tab-list,
  .bp4-tab-indicator-wrapper {
    width: 232px !important;
  }

  .bp4-tabs.bp4-vertical > .bp4-tab-list {
    background-color: #f8f8f8 !important;
  }

  .bp4-tab-indicator-wrapper ~ .bp4-tab {
    background-color: #f8f8f8 !important;
    border-top: 1px solid #ebebeb !important;
    font-size: 14px;
    font-size: 400;
    font-family: 'Roboto', sans-serif;
    min-height: 58px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: unset !important;
  }

  .bp4-tabs.bp4-vertical > .bp4-tab-list .bp4-tab[aria-selected="true"] {
    background-color: #fff !important;
    color: #2d9cdb !important;
    border-top: unset !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 14px;
    min-height: 58px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: unset !important;
  }

  .bp4-tabs.bp4-vertical > .bp4-tab-list .bp4-tab[aria-selected="true"]:focus {
    outline: unset !important;
    box-shadow: unset !important;
  }

  .bp4-tabs.bp4-vertical > .bp4-tab-list .bp4-tab {
    white-space: pre-wrap;
    text-align: center;
    // height: 58px!important;
  }

  .bp4-tabs.bp4-vertical > .bp4-tab-panel {
    width: 100%;
    background-color: #fff !important;
    padding: 5px 20px;
    position: relative;

    .content-image {
      position: relative;
      border-radius: 5px;
      width: 100% !important;
      height: 100% !important;
      margin-top: 5px;
      margin-bottom: 5px;

      .desc-image {
        width: 100%;
        height: 21.91px;
        text-align: center;
        position: absolute;
        bottom: 0;
        background-color: #fff;
        opacity: 0.7;
        color: #ff5151;
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  // .table thead th {
  //   border: 1px solid #b7ddf0;
  //   font-family: 'Roboto', sans-serif;
  //   font-size: 14px;
  // }
  // .table td,
  // .table th {
  //   border: 1px solid #b7ddf0;
  //   font-family: 'Roboto', sans-serif;
  //   font-size: 14px;
  // }

  .content-image {
    position: relative;
    border-radius: 5px;
    width: 41px;
    height: 30px;
  }

  // .table-horizontal {
  //   .p-datatable-thead > tr > th {
  //     padding: 5px;
  //     background-color: #fff !important;
  //     border: 1px solid rgb(183, 221, 240) !important;
  //   }

  //   .p-datatable-tbody > tr > td {
  //     padding: 5px;
  //     background-color: #fff !important;
  //     border: 1px solid rgb(183, 221, 240) !important;
  //   }
  // }

  // .table {
  //   margin-bottom: 0 !important;
  // }
}

// .table-responsives {
//   max-width: 100%;
//   overflow-x: scroll;

//   table td {
//     min-width: 100px;
//   }
// }

.btn-disabled {
  filter: grayscale(100%);
  pointer-events: none;
  cursor: not-allowed;
}

.dashboard-table {
  .p-datatable-tbody > tr > td {
    padding: 10px !important;
    background-color: #fff !important;
  }

  .p-datatable-thead > tr > th {
    padding: 10px !important;
    background-color: #fff !important;
  }
}

.p-calendar{
  width: 100%;
}
